.App {
  text-align: center;
  background-color: bisque;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.carousel {
  width: 900px;
  height: 500px;
  margin: auto;
}

@media (max-width: 900px) {
  .carousel {
    width: auto;
    height: auto;
  }
}
